import { DataTableId } from "./services";

export type PlatformDashboardLinks = ReturnType<typeof platformDashboardLinks>;

export const platformDashboardLinks = () =>{ 
  const prefix = "/"
  return {
    index:`${prefix}`,
    report :(reportKey:number,facilityReference?:string, bookmarkId?:string ) =>{
      const facilityReferencePart = facilityReference? `&facility=${facilityReference}` : ""; 
      const bookmarkPart = bookmarkId? `&bookmark=${bookmarkId}` : ""; 
      return `${prefix}?report-key=${reportKey}${facilityReferencePart}${bookmarkPart}`
    },
    packs:`${prefix}?packs`,
    lists:`${prefix}?lists`,
    viewPack:(packId:number) => `${prefix}?packs&packId=${packId}`,
    badges:`${prefix}?badges`,
    manageFacility:`${prefix}?facility-management`
  } 
};



export const links =
{
  management: {
    reports: {
      detail: (reportKey: number): string => `/Management/Reports/Details/${reportKey}`
    }
  },
  facilityManagement:{
    createUser: (facilityReference:string) => `/?facility-management&ref=${facilityReference}&create`,
    facility: (facilityReference:string) => `/?facility-management&ref=${facilityReference}`,
    facilityUser: (facilityReference:string, userKey:number) => `/?facility-management&ref=${facilityReference}&userkey=${userKey}`
  },
  subscriber: (subscriberName: string) => ({
    reports: {
      detail: (reportKey: number): string => `/subscriber/${subscriberName}/reports/details/${reportKey}`,
      preview:(reportKey: number): string => `/subscriber/${subscriberName}/reports/details/${reportKey}`
    },
    practices:(practiceKey:number) =>({
      users:(): string => `/subscriber/${subscriberName}/Practices/Users/${practiceKey}`
    }),
    report:(reportKey: number) =>({
      preview:(username: string) =>`/subscriber/${subscriberName}/Reports/Preview/${reportKey}?username=${username}`,
      preview2:(username: string, facilityReference?:string) =>{
        var facilityPart = facilityReference ? `&facility=${facilityReference}`: ""
        return `/subscriber/${subscriberName}/Reports/Preview/${reportKey}?username=${username}${facilityPart}`
      }
    }),
    users:() =>({
      detail:(userKey: number) =>`/subscriber/${subscriberName.replace(" ","-")}/Users/Details/${userKey}`
    })
  }),
  login: (inactive: boolean, returnUrl: string) => {
    return `/Identity/Account/Login?${inactive ? "Inactive=true&" : ""}ReturnUrl=${encodeURIComponent(returnUrl)}`
  },
  dashboard: platformDashboardLinks(),
  report:(reportKey:number) => ({
    documentation : `/documentation/${reportKey}`
  }),
  lists:{
    list:(tableId:DataTableId) => `/?list&id=${tableId}`
  },
  measureCategoryView:(reportViewContext:{ reportKey:number,facilityReference?:string, username?: string }, measureCategoryId:number) =>
  {
    return `?measures&report=${reportViewContext.reportKey}&facility=${reportViewContext.facilityReference}&measure-cat=${measureCategoryId}`;
  }, 
  measureCategory:(reportViewContext:{ reportKey:number,facilityReference?:string, username?: string }, measureCategoryId:number, target:"select"| "latest") =>`?measures&report=${reportViewContext.reportKey}&facility=${reportViewContext.facilityReference}&measure-cat=${measureCategoryId}&${target}`,
  measureCategorySaved:(reportViewContext:{ reportKey:number,facilityReference?:string, username?: string }, measureCategoryId:number,reportMeasureCategoryTableId:string) =>`?measures&report=${reportViewContext.reportKey}&facility=${reportViewContext.facilityReference}&measure-cat=${measureCategoryId}&table&report-measure-category-table=${reportMeasureCategoryTableId}`,
  export:(reportKey:number, exportKey:number, facilityReference?:string, username?: string) => {
    let url = `/export?report-key=${reportKey}&export-key=${exportKey}`;
    if (typeof username != 'undefined') {
      url = url +`&username=${username}`;
    }
    if (facilityReference) {
      url = url +`&facility=${facilityReference}`;
    }
    return url;
  },
  markdown:{
    preview:()=> "/api/markdown/preview"
  }
}


